<template>
  <div>
    <ValidationObserver ref="form">
      <div class="card">
        <header class="card-header">
          <p class="card-header-title">Date de identificare voluntar</p>
        </header>
        <div class="card-content">
          <div class="content">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    name="Nume"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      :label="'Nume'"
                      :message="errors[0]"
                    >
                      <b-input
                        class="user-name-input"
                        v-model="volunteer.lastName"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <ValidationProvider
                    name="Prenume"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <b-field
                      :type="errors[0] ? 'is-danger' : ''"
                      :label="'Prenume'"
                      :message="errors[0]"
                    >
                      <b-input
                        class="user-name-input"
                        v-model="volunteer.firstName"
                      />
                    </b-field>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <b-field grouped>
                  <div class="control">
                    <b-button
                      type="is-primary"
                      native-type="submit"
                      @click="addVolunteer"
                      >Salvare</b-button
                    >
                  </div>
                  <div class="control">
                    <b-button type="is-danger is-outlined" @click="cancel()"
                      >Anulare</b-button
                    >
                  </div>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { rawObject } from "@/helpers/utils";
import Volunteering from "../../../services/volunteering.service";
import { pathOr } from "ramda";

export default {
  name: "VolunteerAdd",
  created() {
    const id = parseInt(pathOr(null, ["params", "id"], this?.$route));
    if (id) {
      this.editMode = true;
      this.volunteerId = id;
      this.getVolunteer(this.volunteerId);
    }
  },
  data() {
    return {
      volunteer: {},
      volunteerId: 0,
      editMode: false,
    };
  },
  methods: {
    addVolunteer() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        if (this.editMode) {
          this.$emit("handleEditVolunteer", rawObject(this.volunteer));
        } else {
          this.$emit("handleAddVolunteer", rawObject(this.volunteer));
        }
      });
    },
    async getVolunteer(id) {
      const { getVolunteer } = Volunteering;
      const response = await getVolunteer(id);
      const { data } = response;
      this.volunteer = data;
    },
    cancel() {
      this.$router.push({ path: "/Volunteering" });
    },
  },
};
</script>
